var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"realEstatePanel"},[(_vm.loaded)?_c('div',{staticClass:"mt-2"},[_c('validation-observer',{ref:"refFormObserver"},[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{attrs:{"icon":"SendIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" "+_vm._s(_vm.msg('New Message Template'))+" ")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"1"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Active'),"label-for":"messageTemplate-company"}},[_c('b-checkbox',{attrs:{"switch":""},model:{value:(_vm.activeEvent),callback:function ($$v) {_vm.activeEvent=$$v},expression:"activeEvent"}},[_vm._v(_vm._s(_vm.msg('Active')))])],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Add to all new properties'),"label-for":"messageTemplate-company"}},[_c('b-checkbox',{attrs:{"switch":""},model:{value:(_vm.newProperty),callback:function ($$v) {_vm.newProperty=$$v},expression:"newProperty"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Alias'),"label-for":"messageTemplate-company"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Alias'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"realEstate-sell-price"},model:{value:(_vm.messageTemplate.alias),callback:function ($$v) {_vm.$set(_vm.messageTemplate, "alias", $$v)},expression:"messageTemplate.alias"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3962873336)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Event'),"label-for":"messageTemplate-company"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Event'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.isRTL ? 'rtl' : 'ltr',"clearable":false,"options":_vm.allEvents,"label":"text","reduce":function (branch) { return branch.value; }},model:{value:(_vm.messageTemplate.messageEvent),callback:function ($$v) {_vm.$set(_vm.messageTemplate, "messageEvent", $$v)},expression:"messageTemplate.messageEvent"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1521616827)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Duration in minutes'),"label-for":"messageTemplate-company"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Duration'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.isRTL ? 'rtl' : 'ltr',"clearable":false,"options":_vm.minuteOptions,"label":"label","reduce":function (branch) { return branch.value; }},model:{value:(_vm.messageTemplate.minutes),callback:function ($$v) {_vm.$set(_vm.messageTemplate, "minutes", $$v)},expression:"messageTemplate.minutes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1708863002)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Channel'),"label-for":"messageTemplate-company"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Channel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.isRTL ? 'rtl' : 'ltr',"clearable":false,"options":_vm.allChannels,"label":"text","reduce":function (branch) { return branch.value; }},model:{value:(_vm.messageTemplate.messageChannel),callback:function ($$v) {_vm.$set(_vm.messageTemplate, "messageChannel", $$v)},expression:"messageTemplate.messageChannel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2173648603)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"9","lg":"9"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Real estate'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Properties'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('real-estate-picker',{attrs:{"multiple":""},model:{value:(_vm.messageTemplate.propertyIds),callback:function ($$v) {_vm.$set(_vm.messageTemplate, "propertyIds", $$v)},expression:"messageTemplate.propertyIds"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4131158613)})],1)],1)],1),_c('b-row',{staticClass:"mt-1"},[(_vm.messageTemplate.messageChannel!=null && _vm.messageTemplate.messageChannel==='EMAIL')?_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Subject (relevant for Email event only  - default \'Treasure Home\')'),"label-for":"messageTemplate-company"}},[_c('b-form-input',{attrs:{"id":"realEstate-sell-price"},model:{value:(_vm.messageTemplate.subject),callback:function ($$v) {_vm.$set(_vm.messageTemplate, "subject", $$v)},expression:"messageTemplate.subject"}})],1)],1):_vm._e(),_c('b-col',{staticClass:"mb-2 mt-1",attrs:{"cols":"12"}},[_c('b-form-group',[_c('h5',{staticStyle:{"color":"dodgerblue"}},[_vm._v("Placeholder Options")]),_c('div',{staticClass:"text-center mb-1"},_vm._l((_vm.textTags),function(tag){return _c('b-button',{key:tag.value,staticClass:"mr-1",attrs:{"size":"sm","pill":"","variant":"outline-secondary"},on:{"click":function (){ return _vm.insertPlaceholder(tag.value); }}},[_vm._v(" "+_vm._s(tag.label)+" ")])}),1),_c('validation-provider',{attrs:{"name":_vm.msg('Message body')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{ref:"quillEditor",attrs:{"id":"realEstate-description","spellcheck":"false","value":_vm.messageTemplate.body},on:{"change":_vm.onEditorChange}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2457638947)})],1)],1)],1)],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('h5',[_vm._v("Quick Responses")])]),_c('b-col',{staticClass:"mb-1",attrs:{"xs":"6","sm":"3"}},[_c('b-button',{attrs:{"variant":"outline-primary","block":""},on:{"click":_vm.generateLastnameAndBookingText}},[_vm._v(" "+_vm._s(_vm.msg('Lastname and Bookingcode'))+" ")])],1),_c('b-col',{staticClass:"mb-1",attrs:{"xs":"6","sm":"3"}},[_c('b-button',{attrs:{"disabled":"","variant":"outline-primary","block":""}},[_vm._v("Custom")])],1),_c('b-col',{staticClass:"mb-1",attrs:{"xs":"6","sm":"3"}},[_c('b-button',{attrs:{"disabled":"","variant":"outline-primary","block":""}},[_vm._v("Custom")])],1),_c('b-col',{attrs:{"xs":"6","sm":"3"}},[_c('b-button',{attrs:{"disabled":"","variant":"outline-primary","block":""}},[_vm._v("Custom")])],1)],1)],1):(this.error != null)?_c('div',[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('i',{staticClass:"fas fa-exclamation-triangle mr-3"}),_c('strong',[_vm._v(_vm._s(_vm.error))])])],1):_c('div',[_c('div',{staticStyle:{"min-height":"300px","text-align":"center"}},[_c('div',{staticStyle:{"margin-top":"20%"}},[_c('b-spinner',{attrs:{"label":"Spinning"}}),_vm._v(" "+_vm._s(_vm.msg('loading'))+" ")],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }